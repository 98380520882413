import { Footer } from "../../common/Footer";
import { Main } from "../../common/Main";
import {
  HeaderSection,
  Paragraph,
  StyledWarningIcon,
  WarningHeader,
  WarningSubHeader,
  Wrapper,
} from "./styled";
import warning from "./warning.png";

export const NotFoundPage = () => {
  return (
    <Main>
      <Wrapper>
        <HeaderSection>
          <StyledWarningIcon src={warning} />
          <WarningHeader>Błąd 404</WarningHeader>
          <WarningSubHeader>Ooops! Coś poszło nie tak.</WarningSubHeader>
          <Paragraph>
            Sprawdź czy wpisujesz poprawny adres URL. Strona, której szukasz nie
            istnieje.
          </Paragraph>
        </HeaderSection>
      </Wrapper>
      <Footer />
    </Main>
  );
};
