import { Element } from "./Element";
import { Header, Wrapper, ElementsContainer } from "./styled";
import { ReactComponent as QualityIcon } from "../../../../assets/commonIcons/quality.svg";
import { ReactComponent as CreativityIcon } from "../../../../assets/commonIcons/creativity.svg";
import { ReactComponent as QuickIcon } from "../../../../assets/commonIcons/quick.svg";
import { ReactComponent as IndividualIcon } from "../../../../assets/commonIcons/individual.svg";
import { ReactComponent as InnovationIcon } from "../../../../assets/commonIcons/innovation.svg";
import { ReactComponent as SatisfactionIcon } from "../../../../assets/commonIcons/satisfaction.svg";

export const AboutUs = () => {
  return (
    <Wrapper>
      <Header>Na co możesz liczyć współpracując z nami?</Header>
      <ElementsContainer>
        <Element
          icon={<QualityIcon />}
          title="Jakość i Precyzja"
          content="Wszystkie produkty i usługi dostarczane naszym klientom są wykonywane z najwyższą starannością i dbałością o szczegóły."
        />
        <Element
          icon={<CreativityIcon />}
          title="Kreatywność"
          content="Nasi doświadczeni projektanci graficzni kreują unikalne i oryginalne projekty, dostosowane do indywidualnych potrzeb klientów. Każdy projekt jest skrojony na miarę potrzeb."
        />
        <Element
          icon={<QuickIcon />}
          title="Szybka Realizacja"
          content="Działamy sprawnie i terminowo. Nasza firma dąży do zapewnienia klientom szybkiej realizacji zamówień bez utraty najwyższej jakości naszych produktów."
        />
        <Element
          icon={<IndividualIcon />}
          title="Indywidualne Podejście"
          content="Jesteśmy elastyczni i otwarci na indywidualne potrzeby klientów. Nasza firma stawia na bezpośrednią komunikację, aby zrozumieć oczekiwania i dostosować się do nich."
        />
        <Element
          icon={<InnovationIcon />}
          title="Innowacje"
          content="Śledzimy najnowsze trendy i technologie w branży. Wdrażamy innowacyjne rozwiązania, aby nasze produkty były zawsze nowoczesne i zgodne z najwyższymi standardami."
        />
        <Element
          icon={<SatisfactionIcon />}
          title="Zadowolenie Klienta"
          content="Naszym priorytetem jest satysfakcja klienta. Stawiamy na profesjonalizm, uczciwość i partnerskie relacje, dzięki temu nasi klienci chętnie wracają do naszej firmy."
        />
      </ElementsContainer>
    </Wrapper>
  );
};
