import { ElementIcon } from "./ElementIcon";
import {
  ElementContainer,
  ElementContent,
  ElementTitle,
  ElementIconContainer,
} from "./styled";

export const Element = ({ icon, title, content }) => {
  return (
    <ElementContainer>
      <div>
        <ElementIconContainer>
          <ElementIcon icon={icon} />
        </ElementIconContainer>
      </div>

      <div>
        {" "}
        <ElementTitle>{title}</ElementTitle>
        <ElementContent>{content}</ElementContent>
      </div>
    </ElementContainer>
  );
};
