import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "../../GlobalStyle";
import { themeDark } from "../../theme";
import { NavSection } from "../../common/NavSection";
import { Guide } from "../../features/GuidePage";
import { LandingPage } from "../../features/LandingPage";
import { NotFoundPage } from "../../features/NotFoundPage";

function App() {
  return (
    <ThemeProvider theme={themeDark}>
      <Router>
        <NavSection />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/poradnik" element={<Guide />} /> */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
