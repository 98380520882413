import { Header } from "./Header";
import { Main } from "../../common/Main";
import { Content } from "./Content";
import { Footer } from "../../common/Footer";
import { InBuildingProcess } from "./InBuildingProgress";

export const LandingPage = () => {
  return (
    <Main>
      <Header />
      <Content />
      <InBuildingProcess />
      <Footer />
    </Main>
  );
};
