import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120vh;
  width: 100%;
`;

export const HeaderSection = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3vw 5vw;
  margin-bottom: 5vw;
  background: #00000020;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    margin: 2vw 3vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    padding: 5vw 7vw;
  }
`;

export const StyledWarningIcon = styled.img`
  width: 6vw;
  height: auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    width: 10vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    width: 15vw;
  }
`;

export const WarningHeader = styled.h1`
  margin: 0;
  font-size: 3rem;
  font-weight: 900;
  padding: 2vw 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    margin-top: 2vw;
    font-size: 2.5rem;
  }
`;

export const WarningSubHeader = styled.h2`
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  padding-bottom: 1vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1.6rem;
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 1.5vw;
  font-size: 1.5rem;
  font-weight: 300;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    font-size: 1rem;
    text-align: center;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1.3em;
  }
`;
