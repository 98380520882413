import { StyledLogo } from "../NavSection/Logo";
import { FooterIcon } from "./FooterIcon";
import {
  Address,
  AddressList,
  AddressWrapper,
  ContactWrapper,
  Email,
  FooterIconContainer,
  GridWrapper,
  Header,
  Icon,
  Info,
  InfoHeader,
  InfoSubHeader,
  List,
  ListItem,
  Phone,
  Place,
  Rights,
  RightsBar,
  RightsWrapper,
  SpecialListItemDesktop,
  SpecialHeaderMobile,
  StyledLinkLogo,
  Wrapper,
} from "./styled";
import { ReactComponent as EmailIcon } from "../../assets/footerIcons/email.svg";
import { ReactComponent as GoogleMapsIcon } from "../../assets/footerIcons/google-maps.svg";
import { ReactComponent as PhoneIcon } from "../../assets/footerIcons/phone-call.svg";

export const Footer = () => {
  return (
    <Wrapper id="footer">
      <ContactWrapper>
        <GridWrapper>
          <Info>
            <InfoHeader>Cieszymy się, że do nas trafiłeś!</InfoHeader>
            <InfoSubHeader>Jeśli:</InfoSubHeader>
            <List>
              <ListItem>potrzebujesz więcej informacji, </ListItem>
              <ListItem>chciałbyś złożyć zamówienie,</ListItem>
              <ListItem>szukasz drogi do naszej siedziby,</ListItem>
              <ListItem>lub po prostu chcesz pogadać</ListItem>
              <SpecialListItemDesktop>
                tutaj znajdziesz nasze dane kontaktowe <Icon>👉</Icon>
              </SpecialListItemDesktop>
            </List>
            <SpecialHeaderMobile>
              Poniżej znajdziesz nasze dane kontaktowe <Icon>👇</Icon>
            </SpecialHeaderMobile>
          </Info>
          <AddressWrapper>
            <Header>Zapraszamy!</Header>
            <Address>
              <AddressList>
                <Place
                  title="Google Maps"
                  href="https://maps.app.goo.gl/1aMzQ4VBAD5G9LJA9"
                  target="_blank"
                >
                  <FooterIconContainer>
                    <FooterIcon icon={<GoogleMapsIcon />} />
                  </FooterIconContainer>
                  76-200 Słupsk, ul. Mickiewicza 10
                </Place>
                <Email
                  title="forma.slupsk@gmail.com"
                  href="mailto:forma.slupsk@gmail.com"
                >
                  <FooterIconContainer>
                    <FooterIcon icon={<EmailIcon />} />
                  </FooterIconContainer>
                  forma.slupsk@gmail.com
                </Email>
                <Phone title="tel. +48 786 890 850" href="tel:+48786890850">
                  <FooterIconContainer>
                    <FooterIcon icon={<PhoneIcon />} />
                  </FooterIconContainer>
                  786 890 850
                </Phone>
              </AddressList>
            </Address>
          </AddressWrapper>
        </GridWrapper>
      </ContactWrapper>
      <RightsWrapper>
        <RightsBar>
          <StyledLinkLogo to="/">
            <StyledLogo />
          </StyledLinkLogo>
          <Rights>Wszelkie prawa zastrzeżone. © 2024 FORMA </Rights>
        </RightsBar>
      </RightsWrapper>
    </Wrapper>
  );
};
