import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(223, 212, 196, 0.1);
  border-bottom: 1px solid rgba(223, 212, 196, 0.2);
`;

export const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  margin: 0;
  width: 100%;
  padding: 1.5rem 1vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    padding: 1.5rem 4vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    padding: 1.2rem 5vw;
  }
`;
