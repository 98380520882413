import styled from "styled-components";

export const Wrapper = styled.section`
  max-width: 1280px;
  margin: 10vw auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    margin-top: 20vw;
  }
`;

export const ElementsContainer = styled.div`
  display: grid;
  grid-row-gap: 4rem;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0.5rem;
    grid-column-gap: 1rem;
    margin: 0 2vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 0.5rem;
    grid-column-gap: 1rem;
    margin: 0 2vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 1vw;
    margin: 0 4vw;
  }
`;

export const Header = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1vw;
  margin-bottom: 4vw;
  font-size: 3rem;
  font-weight: 600;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    font-size: 2.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    font-size: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 2rem;
    margin: 4vw 4vw;
    line-height: 1.5;
  }
`;
