import styled from "styled-components";

export const ElementContainer = styled.div`
  display: flex;
  padding: 2vw 1.5vw;
  border-radius: 1.5rem;

  &:hover {
    box-shadow: 0 20px 25px -5px hsla(255, 65%, 12%, 0.5);
    background-color: hsla(255, 65%, 12%, 0.1);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    padding: 3vw 2.5vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    padding: 5vw 4vw;
  }
`;

export const ElementIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.app.elements};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 30%;
  padding: 1rem;
`;

export const ElementTitle = styled.h3`
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0 2rem;
  margin-bottom: 1vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    margin-bottom: 1.5vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    margin-bottom: 3vw;

    font-weight: 700;
  }
`;

export const ElementContent = styled.p`
  font-weight: 400;
  font-size: 1rem;
  margin: 0 2rem;
  line-height: 1.5rem;
  color: #dddddd;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    font-size: 1rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1.05rem;
  }
`;
