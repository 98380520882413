import styled from "styled-components";

export const ItemList = styled.ul`
  margin-top: 5vw;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2em;
`;

export const ItemTile = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.app.background};
  padding: 2vw;
  box-shadow: 1px 1px 10px 10px rgba(9, 10, 51, 0.03);
`;

export const ItemPicture = styled.img`
  width: 7vw;
  height: auto;
`;

export const ItemDescription = styled.div`
  margin-top: 1vw;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
`;

export const ItemName = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-size: 1.3rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textBasic};
`;

export const ItemDetail = styled.span`
  margin: 5px 0;
  padding: 0;
  font-weight: 400;
`;
