import wagrafb2s from "./products/wagraf_b2s.png";
import wagrafb3s from "./products/wagraf_b3s.png";
import wagrafb4s from "./products/wagraf_b4s.png";

export const items = {
  stamps: [
    {
      id: 1,
      producer: "Wagraf",
      model: "b2s",
      size: "39 x 15 mm",
      realSize: "36 x 13 mm",
      rows: "4-5 wierszy",
      picture: `${wagrafb2s}`,
    },
    {
      id: 2,
      producer: "Wagraf",
      model: "b3s",
      size: "48 x 19 mm",
      realSize: "46 x 16 mm",
      rows: "4-5 wierszy",
      picture: `${wagrafb3s}`,
    },
    {
      id: 3,
      producer: "Wagraf",
      model: "b4s",
      size: "59 x 25 mm",
      realSize: "56 x 23 mm",
      rows: "6-7 wierszy",
      picture: `${wagrafb4s}`,
    },
  ],
};
