import styled from "styled-components";

const Stamp = styled.div`
  width: 5vw;
  position: absolute;
  top: 150px;
  right: 70px;
  animation: rotate 20s linear infinite;

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    width: 7vw;
    top: 135px;
    right: 40px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    width: 9vw;
    top: 110px;
    right: 30px;
  }
`;

export const StyledStamp = ({ icon }) => {
  return <Stamp>{icon}</Stamp>;
};
