import { TypingLine } from "../TypingLine";
import {
  MainHeader,
  Paragraph,
  StampsWrapper,
  StyledDater,
  StyledText,
  StyledTextStampExample,
  SubHeader,
  TypingLineWrapper,
  Wrapper,
} from "./styled";
import { ReactComponent as StampCheck } from "../../../assets/animated_stamps/check.svg";
import { StyledStamp } from "./AnimatedLogos";
import { Button } from "./Button";

export const Header = () => {
  return (
    <Wrapper>
      <StyledStamp icon={<StampCheck />} />
      {/* <SubHeader>Pomysł. Plan. Realizacja.</SubHeader> */}
      <MainHeader>
        <StyledText> Stwórz z nami</StyledText>
        <TypingLineWrapper>
          <TypingLine
            content={[
              "pieczątki",
              "stemple",
              "wizytówki",
              "grawertony",
              "projekty",
            ]}
          />
        </TypingLineWrapper>
      </MainHeader>
      <Paragraph>
        nietuzinkowe, oryginalne i&nbsp;trudne do&nbsp;przeoczenia,
        <br /> które pokochają Twoi klienci.
      </Paragraph>
      <Button title="Sprawdź nas" smooth to="/#footer">
        Sprawdź nas
      </Button>
      <StampsWrapper>
        <StyledDater />
        <StyledTextStampExample />
      </StampsWrapper>
    </Wrapper>
  );
};
