import styled, { keyframes } from "styled-components";

const scrollAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const Image = styled.img`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  filter: grayscale(100%);
  opacity: 0.75;
`;

export const SvgIcon = styled.div`
  width: 100%;
  height: 100%;
`;

export const ListItem = styled.div`
  margin-right: 15vw;
  display: flex;
  flex-direction: column;
`;

export const ClientList = styled.ul`
  list-style: none;
  display: flex;
`;

export const ScrollingContainer = styled.div`
  display: flex;
  white-space: nowrap;
  animation: ${scrollAnimation} 60s linear infinite;
`;
