import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 5vw auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    margin: 2vw auto;
    padding-bottom: 5vw;
    max-width: 90vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    margin: 2vw auto;
    padding-bottom: 15vw;
    max-width: 90vw;
  }
`;

const BuildingWarningHeader = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    padding-bottom: 4vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    padding-bottom: 8vw;
    font-size: 1.75rem;
  }
`;

const BuildingWarningParagraph = styled.p`
  padding-top: 1vw;
  margin: 0;
  color: #dddddd;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.8;
  letter-spacing: 0.03rem;
  text-align: justify;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    font-size: 1.2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1.2rem;
  }
`;

export const InBuildingProcess = () => (
  <Wrapper>
    <BuildingWarningHeader>Szanowni Państwo</BuildingWarningHeader>
    <BuildingWarningParagraph>
      Nasza strona dopiero powstaje. Stworzyliśmy jej tymczasową wersję, tak aby
      umożliwić Państwu odnalezienie nas. W stopce znajdą Państwo dane
      kontaktowe. Niebawem pojawią się nowe funkcjonalności i zakładki z większą
      ilością informacji. Dziękujemy za Waszą wyrozumiałość 😊
    </BuildingWarningParagraph>
  </Wrapper>
);
