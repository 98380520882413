import styled from "styled-components";
import { ReactComponent as DaterExample } from "../../../assets/workExamples/D30.svg";
import { ReactComponent as TextStampExample } from "../../../assets/workExamples/w3.svg";

export const Wrapper = styled.header`
  margin-top: 12vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  justify-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    margin-top: 20vw;
  }
`;

export const SubHeader = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
  padding: 0;
`;

export const MainHeader = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.colors.textBasic};
  font-size: 4vw;
  margin-bottom: 1vw;
  letter-spacing: 0.05rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    font-size: 7vw;
    margin-bottom: 2vw;
  }
`;

export const StyledText = styled.span`
  font-weight: 300;
  text-align: center;
`;

export const TypingLineWrapper = styled.span`
  margin-left: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.basic};

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    margin-left: 12px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    margin-left: 8px;
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: 2vw;
  font-weight: 200;
  letter-spacing: 0.05rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.textBasic};

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    font-size: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1.3rem;
    line-height: 1.5;
    padding: 0 1vw;
    font-weight: 200;
  }
`;

export const StampsWrapper = styled.div`
  padding-top: 7vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    padding-top: 6vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    padding-top: 10vw;
  }
`;

export const StyledDater = styled(DaterExample)`
  width: 15rem;
  height: auto;
  position: relative;
  left: 400px;
  transform: rotate(15deg);

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    width: 11rem;
    left: 250px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    width: 8rem;
    left: 150px;
  }
`;

export const StyledTextStampExample = styled(TextStampExample)`
  width: 15rem;
  height: auto;
  position: relative;
  right: 400px;
  transform: rotate(-10deg);

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    width: 12rem;
    right: 300px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    width: 8rem;
    right: 175px;
  }
`;
