import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
    }

    *, ::after, ::before {
        box-sizing: inherit;
    }

    body {
        font-family: 'Roboto', sans-serif;
        word-break: break-word;
        transition: background 0.3s;
        font-size: 16px;
        /* background: linear-gradient(to bottom, #1a1b52, transparent), linear-gradient(to top, #1a1b55, transparent), radial-gradient(circle at 0 0, hsla(24, 100%, 54%,.75), rgba(0, 0, 0, 0.5)); */
        background: ${({ theme }) => theme.colors.app.background};
        position: relative;
        z-index: 1;
        /* background: ${({ theme }) => theme.colors.app.background}; */
        min-height: 200vh;
        color: ${({ theme }) => theme.colors.app.text};
        letter-spacing: -0.01em;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        scroll-behavior: smooth;
    }

    body::before {
        content: "";
        display: block;
        min-height: 200vh;
        width: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        background-image:linear-gradient(to top, ${({ theme }) =>
          theme.colors.app
            .background}, #28224999), radial-gradient(circle at top,${({
  theme,
}) => theme.colors.basic}, transparent);
    }
`;
