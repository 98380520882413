import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/forma_new.svg";

export const StyledLogo = styled(Logo)`
  width: 10vw;
  height: auto;
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.textBasic};
  transition: color 0.5s;

  &:hover {
    color: #ffffff52;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    width: 16vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    width: 30vw;
  }
`;
