import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

export const Button = styled(HashLink)`
  text-decoration: none;
  margin-top: 3vw;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 2.5rem;
  background: ${({ theme }) => theme.colors.basic};
  color: ${({ theme }) => theme.colors.textBasic};
  font-weight: 600;
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;

  &:hover {
    transform: translateY(-10px);
  }

  &:active {
    background-color: #ffffff52;
    color: black;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    padding: 0.9rem 2.2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1rem;
    padding: 0.75rem 2rem;
  }
`;
