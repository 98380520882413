import { Typewriter } from "react-simple-typewriter";

export const TypingLine = ({ content }) => {
  return (
    <Typewriter
      words={content}
      loop={""}
      cursor
      cursorColor={`${({ theme }) => theme.colors.app.text}`}
      cursorStyle="_"
      typeSpeed={100}
      deleteSpeed={50}
      delaySpeed={2000}
    />
  );
};
