import { AboutUs } from "./AboutUs";
import { Clients } from "./Clients";
import { Services } from "./Services";

export const Content = () => {
  return (
    <>
      <AboutUs />
      {/* <Clients /> */}
      {/* <Services /> */}
    </>
  );
};
