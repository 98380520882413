import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.footer`
  border-top: 1px solid #ffffff32;
  width: 100%;
  padding-top: 5vw;
  background: #0c0d0f;
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    padding-top: 0;
  }
`;

export const ContactWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 1vw;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    width: 100%;
    padding: 5vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
  }
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3vw;
  background-color: #ffffff09;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #bbbbbb;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    border-right: none;
  }
`;

export const InfoHeader = styled.h2`
  padding-bottom: 2.5vw;
  margin: 0;
  font-size: 2.2rem;
  font-weight: 700;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    font-size: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1.4rem;
    padding: 3vw 2vw;
  }
`;

export const InfoSubHeader = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    font-size: 1.4rem;
    font-weight: 500;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1.3rem;
    font-weight: 500;
    padding: 1vw 2vw;
  }
`;

export const List = styled.ul`
  line-height: 3rem;
  padding-left: 1.3rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    padding-left: 1.7rem;
  }
`;

export const ListItem = styled.li`
  font-size: 1.3rem;
  letter-spacing: 0.03rem;
  font-weight: 400;
  color: #ffc86ff1;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1.1rem;
  }
`;

export const SpecialListItemDesktop = styled.li`
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  list-style: none;
  color: #ffffff;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    display: none;
  }
`;

export const SpecialHeaderMobile = styled.h3`
  display: none;
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.03rem;
  padding-bottom: 3vw;
  color: #ffffff;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    display: block;
    text-align: center;
    margin: 1vw 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1.4rem;
    margin: 0 2vw;
    padding-bottom: 2rem;
  }
`;

export const Icon = styled.span`
  font-size: 1.5rem;
  padding: 0 0.75rem;
`;

export const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Address = styled.address`
  color: ${({ theme }) => theme.colors.textBasic};
  font-weight: 500;
  padding: 1rem 3rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    padding: 2vw 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    padding: 5vw 3vw;
  }
`;

export const Header = styled.h3`
  margin: 0;
  padding-bottom: 2vw;
  text-decoration: none;
  font-style: bold;
  text-align: center;
  font-weight: 600;
  font-size: 1.75rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 1.5rem;
  }
`;

export const AddressList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3vw;
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    flex-direction: row;
    gap: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    flex-direction: column;
    gap: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    flex-direction: column;
  }
`;

export const FooterIconContainer = styled.div`
  display: flex;
  width: 45px;
  height: 45px;
  padding: 10px;
  background: #eeeeee30;
  border: 1px solid #eeeeee80;
  border-radius: 30%;
  color: #ffc86ff1;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    width: 40px;
    height: 40px;
  }
`;

export const Place = styled.a`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1vw;
  align-items: center;
  text-decoration: none;
  transition: 0.3s;
  color: inherit;
  font-style: inherit;

  &:hover {
    color: #ffffff52;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    grid-gap: 1.5vw;
    font-size: 1.2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    grid-gap: 4vw;
    font-size: 1rem;
  }
`;

export const Email = styled.a`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1vw;
  align-items: center;
  text-decoration: underline;
  font-style: inherit;
  color: inherit;
  transition: 0.3s;

  &:hover {
    color: #ffffff52;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    grid-gap: 1.5vw;
    font-size: 1.2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    grid-gap: 4vw;
    font-size: 1rem;
  }
`;

export const Phone = styled.a`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1vw;
  align-items: center;
  text-decoration: none;
  font-style: inherit;
  color: inherit;
  transition: 0.3s;

  &:hover {
    color: #ffffff52;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    grid-gap: 1.5vw;
    font-size: 1.2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    grid-gap: 4vw;
    font-size: 1rem;
  }
`;

export const RightsWrapper = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 4vw;
  background-color: #ffffff04;
  border-top: 1px solid #ffffff32;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    margin: 0;
  }
`;

export const RightsBar = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    max-width: 90vw;
  }
`;

export const Rights = styled.div`
  width: 100%;
  font-weight: 200;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 0.03rem;
  padding: 3vw 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    font-size: 0.9rem;
    padding: 3.5vw 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    font-size: 0.6rem;
    padding: 5vw 0;
  }
`;

export const StyledLinkLogo = styled(Link)`
  display: flex;
  width: 100%;
  height: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletHorMax}px) {
    width: 20vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    width: 25vw;
  }
`;
