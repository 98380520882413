import dębnica from "../../../../assets/clients/dębnica.png";
import facebook from "../../../../assets/clients/facebook.png";
import { StyledKobylnica } from "./SvgIcon/styled";

export const clients = [
  {
    id: 1,
    name: "Gmina Kobylnica",
    image: null,
    // vector: <StyledKobylnica />,
  },
  {
    id: 2,
    name: "Google",
    image: null,
    // vector: <Google />,
  },
  {
    id: 3,
    name: null,
    image: `${dębnica}`,
    width: "100px",
    height: "auto",
    vector: null,
  },
  {
    id: 4,
    name: null,
    image: `${facebook}`,
    width: "70px",
    height: "auto",
    vector: null,
  },
];
