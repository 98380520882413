import { StyledLogo } from "./Logo";
import { NavBar } from "./NavBar";
import { Nav, NavWrapper } from "./styled";
import { Link } from "react-router-dom";

export const NavSection = () => {
  return (
    <Nav>
      <NavWrapper>
        <Link title="FORMA - Pieczątki" to="/">
          <StyledLogo />
        </Link>
        <NavBar />
      </NavWrapper>
    </Nav>
  );
};
