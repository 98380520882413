import { NavList, StyledContactLink, StyledLink } from "./styled";

export const NavBar = () => {
  return (
    <NavList>
      <StyledLink title="O nas" to="/">
        O nas
      </StyledLink>
      {/* <StyledLink to="/poradnik">Poradnik</StyledLink> */}
      <StyledContactLink title="Kontakt" smooth to="/#footer">
        Kontakt
      </StyledContactLink>
    </NavList>
  );
};
