import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const NavList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  justify-items: center;
  align-items: center;
  grid-gap: 1rem;
  margin: 0;
  padding: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    grid-gap: 0;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: 1rem;
  font-weight: 500;
  transition: border-bottom 0.5s;
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0.75rem 1rem;
  letter-spacing: normal;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom-color: ${({ theme }) => theme.colors.basic};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    padding: 0.5rem 0.5rem;
    font-size: 0.9rem;
  }
`;

export const StyledContactLink = styled(HashLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 1.5rem;
  background-color: hsla(255, 65%, 12%, 0.2);
  vertical-align: center;
  height: 2.5em;
  border-radius: 5px;
  margin: 0;
  transition: 0.3s ease;

  &:hover {
    background-color: hsla(255, 65%, 12%, 0.8);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletVerticalMax}px) {
    padding: 0 1.2rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMax}px) {
    padding: 0 1rem;
    font-size: 0.9rem;
  }
`;
